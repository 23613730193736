import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { Link as RouterLink } from '~/shared/link';
import { useStaticContent } from '~/libs/queries/bff';
import { Svg } from '~/shared/svg';
import { INavigationCommon, IServiceLink } from '../navigation.d';
import Loader from '~/shared/loader/loader.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './service.module.scss';
import { getInitials } from '~/helpers';
import useNavigationLayout from '~/context/use-navigation.context';
import { useManualWebOrdersCount } from '~/libs/queries/users/hooks/use-manual-web-orders-count';
import useUser from '~/libs/use-user';
import { Portal } from '~/shared/portal';
import ServiceDropdown from './service-dropdown';
import { useCurrentBasket, useIsUpdatingBasket } from '~/libs/queries/basket';

const HeaderLink: FC<IServiceLink> = ({ icon, iconText, badge, isActive, className, onClick, children, ...rest }) => (
    <RouterLink
        onClick={onClick}
        className={clsx(styles.link, className, {
            [styles.active]: isActive,
        })}
        {...rest}
    >
        <span
            className={clsx(styles.circle, {
                [styles.circleText]: Boolean(iconText),
            })}
        >
            {iconText || <Svg name={icon} className={styles.icon} />}

            <AnimatePresence>
                {badge && (
                    <motion.span
                        className={styles.badge}
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.5 }}
                        transition={{ duration: 0.18 }}
                    >
                        {badge}
                    </motion.span>
                )}
            </AnimatePresence>
        </span>

        {children}
    </RouterLink>
);

const Service: FC<INavigationCommon> = ({ isPortable }) => {
    const { navState, setNavState } = useNavigationLayout();
    const isUpdatingBasket = useIsUpdatingBasket();
    const translate = useTranslations();
    const { profile, isInternalUser } = useUser();

    const { data: basket } = useCurrentBasket();


    const { data: staticContent } = useStaticContent();
    const { data } = useManualWebOrdersCount();
    const profileIsOpen = (navState.profileIsOpen && isInternalUser) || (navState.profileIsOpen && isPortable);
    const myFtz = staticContent?.myFtz;
    const header = staticContent?.header;
    const settingsArea = staticContent?.settingsArea;

    const closeAllNavigations = () => {
        if (navState.isOpen || navState.searchIsOpen || navState.profileIsOpen) {
            setNavState({
                isOpen: false,
                searchIsOpen: false,
                profileIsOpen: false,
            });
        }
    };

    const settingsRef = useOnclickOutside(
        () => {
            setNavState({ settingsIsOpen: false });
        },
        {
            disabled: navState.isOpen || !navState.settingsIsOpen,
        },
    );

    const myFtzRef = useOnclickOutside(
        () => {
            setNavState({ profileIsOpen: false });
        },
        {
            // Disable the event listener to avoid colliding with other click events
            disabled: navState.isOpen || !navState.profileIsOpen,
        },
    );

    if (!myFtz || !header || !settingsArea) {
        return null;
    }

    // const isUpdatingBasket = updatingBasketContent || isValidatingBasket

    return (
        <nav className={styles.service}>
            <HeaderLink
                icon="search"
                className={styles.search}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                badge={navState.searchHasItems && 1}
                onClick={() => {
                    setNavState({
                        isOpen: false,
                        searchIsOpen: !navState.searchIsOpen,
                        profileIsOpen: false,
                    });
                }}
            >
                {translate('header.search', 'Søg')}
            </HeaderLink>

            <div className={styles.linkWrapper} ref={myFtzRef}>
                <HeaderLink
                    isActive={profileIsOpen}
                    href={isInternalUser ? undefined : header?.links?.overviewPage?.url}
                    icon="avatar"
                    iconText={profile ? getInitials(profile.name) : null}
                    onClick={(event) => {
                        if (isInternalUser || isPortable) {
                            event.preventDefault();

                            setNavState({
                                isOpen: false,
                                searchIsOpen: false,
                                settingsIsOpen: false,
                                profileIsOpen: !navState.profileIsOpen,
                            });
                        }
                    }}
                >
                    {header?.links?.overviewPage?.title ?? 'Mit FTZ'}
                </HeaderLink>

                {isPortable ? (
                    <Portal>
                        <ServiceDropdown
                            ref={myFtzRef}
                            isActive={!!profileIsOpen}
                            title={header?.links?.overviewPage?.title || '#Mit Ftz'}
                            closeDropdownMenu={() => setNavState({ profileIsOpen: false })}
                            links={myFtz}
                            addCreditLink
                        />
                    </Portal>
                ) : (
                    <ServiceDropdown
                        isActive={!!profileIsOpen}
                        title={header?.links?.overviewPage?.title || '#Mit Ftz'}
                        closeDropdownMenu={() => setNavState({ profileIsOpen: false })}
                        links={myFtz}
                    />
                )}
            </div>

            <div className={clsx(styles.linkWrapper, styles.settingsLink)} ref={settingsRef}>
                <HeaderLink
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    badge={isInternalUser && data?.count}
                    isActive={navState?.settingsIsOpen}
                    icon="settings"
                    onClick={() => {
                        setNavState({
                            settingsIsOpen: !navState.settingsIsOpen,
                        });
                    }}
                >
                    {translate('header.settings', 'Indstillinger')}
                </HeaderLink>

                {isPortable ? (
                    <Portal>
                        <ServiceDropdown
                            ref={settingsRef}
                            isActive={!!navState.settingsIsOpen}
                            title={translate('header.settings', 'Indstillinger')}
                            closeDropdownMenu={() => setNavState({ settingsIsOpen: false })}
                            withLogout
                            links={settingsArea}
                        />
                    </Portal>
                ) : (
                    <>
                        <ServiceDropdown
                            isActive={!!navState.settingsIsOpen}
                            title={header?.links?.overviewPage?.title || '#Mit Ftz'}
                            closeDropdownMenu={() => setNavState({ settingsIsOpen: false })}
                            withLogout
                            links={settingsArea}
                        />
                    </>
                )}
            </div>

            <div className={styles.basketLinkWrapper}>
                {isUpdatingBasket && (
                    <div className={styles.loaderWrapper}>
                        <Loader color="secondary" className={styles.loader} size="35px" borderWidth="3px" />
                    </div>
                )}
                <HeaderLink
                    className={isUpdatingBasket ? styles.updatingBasket : undefined}
                    icon="checkout"
                    {...(isUpdatingBasket ? null : { href: header?.links?.basketPage?.url })}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    badge={isUpdatingBasket ? null : basket?.totalItemsCount}
                    prefetch={false}
                    onClick={closeAllNavigations}
                >
                    {translate('basket.basket', 'Kurv')}
                </HeaderLink>
            </div>
        </nav>
    );
};

export default Service;
